@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?xcdxl1');
  src:  url('fonts/icomoon.eot?xcdxl1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?xcdxl1') format('truetype'),
    url('fonts/icomoon.woff?xcdxl1') format('woff'),
    url('fonts/icomoon.svg?xcdxl1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-court-layout:before {
  content: "\e903";
}
.icon-court-options:before {
  content: "\e904";
}
.icon-cross-courts:before {
  content: "\e905";
}
.icon-room-size:before {
  content: "\e906";
}
.icon-arrow-expand-right:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-cloud-upload:before {
  content: "\e902";
}
.icon-format-text:before {
  content: "\e919";
}
.icon-paint-format:before {
  content: "\e90c";
}
.icon-image:before {
  content: "\e931";
}
.icon-images:before {
  content: "\e932";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-folder-plus:before {
  content: "\e933";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-price-tags:before {
  content: "\e936";
}
.icon-barcode:before {
  content: "\e937";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-download:before {
  content: "\e960";
}
.icon-upload:before {
  content: "\e961";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-share:before {
  content: "\ea7d";
}
.icon-mail:before {
  content: "\ea83";
}
