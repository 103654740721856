html,
body {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-color: #ebeaea;
}

@font-face {
  font-family: HelveticaNeue-Bold;
  src: url('../fonts/HelveticaNeue-Bold.ttf');
}
@font-face {
  font-family: HelveticaNeue-Medium;
  src: url('../fonts/HelveticaNeue-Medium.ttf');
}
@font-face {
  font-family: HelveticaNeue-Regular;
  src: url('../fonts/HelveticaNeue-Regular.ttf');
}

.MuiPaper-elevation4 {
  box-shadow: 0 0 10px 0 #c0bdbd;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0px 3px 0 #c0bdbd;
}

.hover {
  text-decoration: none;
}
.hover:hover {
  cursor: pointer;
  text-decoration: none;
  opacity: 0.8;
}

.track-horizontal {
  min-width: 100%;
  z-index: 150;
  bottom: 0;
}
.thumb-horizontal {
  cursor: pointer;
  background: red;
  min-width: 100px;
}
